import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationService } from './service/authentication.service';
import { SharedModule } from './shared/shared.module';
import { ErpCommonService } from './service/erp-common.service';
import { NgxElectronModule } from 'ngx-electron';
import { ApiPrefixInterceptor, SuccessErrorInterceptor } from './_helpers';
import { CommonAPIService } from './service/commonAPI.service';
import { CookieService, CookieModule } from 'ngx-cookie';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SafePipe } from './safe.pipe';
import { AuthPopoverPage } from './pages/auth-popover/auth-popover';
// import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
// import { Media } from '@awesome-cordova-plugins/media/ngx';
// import {MatProgressBarModule} from '@angular/material/progress-bar';
import { VideoPlayer } from '@awesome-cordova-plugins/video-player/ngx';
// import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PdfViewerModule,
    // DocumentViewer,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NgxElectronModule,
    NgxExtendedPdfViewerModule,
    // MatProgressBarModule,
    // DocumentViewer,
    // Media,
    CookieModule.forRoot()
  ],
  declarations: [AppComponent, SafePipe, AuthPopoverPage],
  providers: [InAppBrowser, AuthenticationService, CommonAPIService, ErpCommonService, CookieService, VideoPlayer, 

    { provide: HTTP_INTERCEPTORS, useClass: ApiPrefixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SuccessErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
