import { Component, Input, Output } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Storage } from "@ionic/storage";

@Component({
  selector: 'page-auth-popover',
  templateUrl: 'auth-popover.html',
  styleUrls: ['./auth-popover.scss'],
})
// @Component({
//   template: `
//     <ion-list>
//       <ion-select (ionChange)="changeSubject($event)" placeholder="Select Subject">
//         <ion-select-option *ngFor="let item of subjectData" value="item.sub_id">{{item.sub_name}}</ion-select-option>
//       </ion-select>
//     </ion-list>
//   `,
// })
export class AuthPopoverPage {
  subjectData: any;
  @Input() content: any;
  @Output() validateUserStatus: boolean;
  contentData : any;
  auth = {
    name : "",
    code : ""
  }
  localAttempts: any;

  constructor(
    public popoverCtrl: PopoverController,
    private storage: Storage
  ) {}

  ngOnInit() {
    
  }

  async validateAuthCode(){
    let authName = "kabir";
    let authCode = "741ANZ258"
    console.log("name ", this.auth.name);
    console.log("name ", this.auth.code);
    this.localAttempts = await this.storage.get("auth2");
    if(!this.localAttempts){
      let attempts = [{
        no : 1,
        user : this.auth.name,
        pass : this.auth.code
      }];
      await this.storage.set("auth2", attempts);
    }else{
      let auth2 = JSON.parse(this.localAttempts);
      let attempts = auth2.push({
        no : auth2.no ++,
        user : this.auth.name,
        pass : this.auth.code
      });
      await this.storage.set("auth2", attempts);
    }

    console.log("localattempts", this.localAttempts);
    
    if(this.auth.code == authCode && authName == this.auth.name){
      this.validateUserStatus = true;
      // alert("Uploader Ready");
      this.closePopover({validUser : true, authName: authName});
    }else{
      this.validateUserStatus = false;
      // alert("Invalid Code");
      this.closePopover({validUser : false});
    }
  }

  closePopover(data) {
    // this.app.getRootNavs()[0].push('/support');
    this.popoverCtrl.dismiss(data);
  }

  close(url: string) {
    this.popoverCtrl.dismiss();
  }
}
