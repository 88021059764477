import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommonAPIService } from './commonAPI.service';
import { LoadingController, ToastController } from '@ionic/angular';
@Injectable()
export class ErpCommonService {
	processType: any;
	private qelementBaseUrl: string = '/api/qelement';
	question_type: any;
	roleMessage: string;
	loading: HTMLIonLoadingElement;
	loginId: any;
	constructor(private http: HttpClient, private service: CommonAPIService,
		private toastController: ToastController,
		private loadingCtrl: LoadingController) { }

	
	async presentToast(message, duration) {
		const toast = await this.toastController.create({
			message: message,
			duration: duration,
			// position: 'middle'
			// buttons: [
			//   {
			//     text: 'More Info',
			//     role: 'info',
			//     handler: () => { this.handlerMessage = 'More Info clicked'; }
			//   },
			//   {
			//     text: 'Dismiss',
			//     role: 'cancel',
			//     handler: () => { this.handlerMessage = 'Dismiss clicked'; }
			//   }
			// ]
		});

		await toast.present();

		const { role } = await toast.onDidDismiss();
		this.roleMessage = `Dismissed with role: ${role}`;
	}
	async showLoading() {
		this.loading = await this.loadingCtrl.create({
			message: 'Loading...',
			duration: 3000,
			spinner: 'circles',
		});
		this.loading.present();
	}

	dismissLoading(){
		this.loading.dismiss();
	}
	
	getEmployeeDetail(value) {

		return this.http.post(environment.apiHRUrl + '/employee/get', value);
	}
	getShiftAttendance(value) {
		return this.http.post(environment.apiHRUrl + '/shiftattendance/getall', value);
	}
	public insertQuestion(value: any) {
		return this.http.post(environment.apiAxiomUrl + '/question/addQuestion', value);
	}
	getShiftSeasonAttendance(value) {
		return this.http.post(environment.apiHRUrl + '/common/getSessionStaffAttendance', value);
	}
	getBoard() {
		return this.http.get('/setup/board');
	}
	// fetch class
	getClass(param) {
		return this.http.post(environment.apiAxiomUrl + '/setupdetail/getClassData', param);
	}
	getAllEmployeeLeaveData(value) {
		return this.http.post(environment.apiHRUrl + '/attendance/getSessionAttendance', value);
	}
	getAllEmployeeLeaveData2() {
		return this.http.get(environment.apiHRUrl + '/employee-leave-management/getAllEmployeeLeaveData');
	}
	GetHolidayDays(value) {
		return this.http.post(environment.apiSmartUrl + '/common/GetHolidayDays', value);
	}
	getAllUser(value) {
		return this.http.post(environment.apiHRUrl + '/employee/getAllEmployee', value);
	}
	getShiftAttendanceAll(value) {
		return this.http.post(environment.apiHRUrl + '/shiftattendance/getall', value);
	}
	getUserAccessTopic(param) {

		return this.http.post(environment.apiAxiomUrl + '/users/getUserAccessTopic', param);
	}
	getTopicByClassSubject2(param) {

		return this.http.post(environment.apiAxiomUrl + '/setupdetail/getTopicByBoardClassSubject', param);
	}
	getUserAccessSubject(param) {
		return this.http.post(environment.apiAxiomUrl + '/users/getUserAccessSubject', param);
	}
	getHolidayOnly(value) {
		return this.http.post(environment.apiSmartUrl + '/common/getHolidayOnly', value);
	}
	getSubtopicByTopic(topic_id) {
		return this.http.get(environment.apiAxiomUrl + `/setupdetail/getSubtopicByTopic/${topic_id}`);
	}
	getQtype() {
		return this.http.get(environment.apiAxiomUrl + `${this.qelementBaseUrl}/qtype`);
	}
	getQsubtype() {
		return this.http.get(environment.apiAxiomUrl + `${this.qelementBaseUrl}/subqtype`);
	}

	getStudentInvoice(value) {
		return this.http.post(environment.apiFeeUrl + '/feeOnlineTransaction/getStudentInvoice', value);
	}
	getFeeMonths(value) {
		return this.http.get(environment.apiFeeUrl + '/feeSetup/getFeeMonths');
	}
	downloadInvoice(value: any) {
		return this.http.post(environment.apiFeeUrl + '/invoice/printInvoice', value);
	}
	getQTypeFromApi() {
		return this.http.get(environment.apiAxiomUrl + '/setup/question_type/1');
	}
	getSkillData() {

		return this.http.get(environment.apiAxiomUrl + '/setupdetail/getSkillData');
	}
	// fetch level of difficulty
	getLodData() {

		return this.http.get(environment.apiAxiomUrl + '/setupdetail/getLodData');
	}
	setQType(value) {
		this.question_type = [];
		this.question_type = value;
	}
	getMaster(value) {
		return this.http.post(environment.apiHRUrl + '/common/findAll', value);
	}
	getStudentDashboardAttendance(value) {

		return this.http.post(environment.apiExamUrl + '/auxiliaries/getStudentDashboardAttendance', value);
	}
	getStudentAttendence(value) {

		return this.http.post(environment.apiExamUrl + '/report/getStudentAttendence', value);
	}
	getSection(value) {
		return this.http.post(environment.apiSmartUrl + '/setup/getSection', value);
	}
	getMessage(value) {
		return this.http.post(environment.apiHRUrl + '/communication/getAll', value);
	}
	checkAttendance(value) {
		return this.http.post(environment.apiHRUrl + '/attendance/checkAttendance', value);
	}
	updateMessage(value) {
		return this.http.post(environment.apiHRUrl + '/communication/update', value);
	}
	insertMessage(value) {
		return this.http.post(environment.apiHRUrl + '/communication/insert', value);
	}
	getEmployeeLeaveData(value) {
		return this.http.post(environment.apiHRUrl + '/employee-leave-management/get', value);
	}
	getAttendanceReportClassSectionWise(value) {
		return this.http.post(environment.apiExamUrl + '/auxiliaries/getAttendanceReportClassSectionWise', value);
	}
	getFeeLedger(value: any) {
		return this.http.post(environment.apiFeeUrl + '/feeTransaction/getFeeLedger/', value);
	}
	getAllEmployee(value) {
		return this.http.post(environment.apiHRUrl + '/employee/getAllEmployee', value);
	}
	getClassTeacher(value) {
		return this.http.post(environment.apiExamUrl + '/auxiliaries/ctForClass', value);
	}
	insertEmployeeLeaveData(value) {
		return this.http.post(environment.apiHRUrl + '/employee-leave-management/insert', value);
	}
	updateEmployee(value) {
		return this.http.post(environment.apiHRUrl + '/employee/update', value);
	}
	checkIfSchoolExists(value: any) {
		return this.http.post(environment.apiSisUrl + '/users/checkIfSchoolExists', value);
	}
	getDepartment(value) {
		return this.http.get(environment.apiSisUrl + '/siSetup/department');
	}
	getCategoryOne(value) {
		return this.http.post(environment.apiHRUrl + '/category-one/getAll', value);
	}
	uploadDocuments(value) {
		return this.http.post(environment.apiSisUrl + '/documents/uploadDocuments', value);
	}
	getFilterData(value) {
		return this.http.post(environment.apiHRUrl + '/employee/getFilterData', value);
	}
	updateEmployeeLeaveData(value) {
		return this.http.post(environment.apiHRUrl + '/employee-leave-management/update', value);
	}
	getLeaveManagement() {
		return this.http.get(environment.apiHRUrl + '/leave-management/getLeaveManagement');
	}
	getStudentFeeOutstanding(value: any) {
		return this.http.post(environment.apiFeeUrl + '/feeOnlineTransaction/getStudentFeeOutstanding', value);
	}
	makeTransaction(value: any) {
		return this.http.post(environment.apiFeeUrl + '/feeOnlineTransaction/makeTransaction', value);
	}
	getUser(value: any) {
		return this.http.post(environment.apiAxiomUrl + '/users/getUser', value);
	}
	getOnlineTransaction(value: any) {
		return this.http.post(environment.apiFeeUrl + '/feeOnlineTransaction/getOnlineTransaction', value);
	}

	downloadReceipt(value: any) {
		return this.http.post(environment.apiFeeUrl + '/feeTransaction/printReceipt', value);
	}
	getStudentDetails(value) {
		return this.http.post(environment.apiSisUrl + '/studentDetails/getStudentDetails', value);
	}
	getStudentInformation(value) {
		return this.http.post(environment.apiSisUrl + '/studentinfo/getStudentInformation', value);
	}
	getStudent(value) {
		return this.http.post(environment.apiSisUrl + '/studentinfo/getStudent', value);
	}

	checkForPaymentStatus(value: any) {
		return this.http.post(environment.apiFeeUrl + '/feeOnlineTransaction/checkForPaymentStatus', value);
	}
	paymentResponseMobile(value: any) {
		return this.http.post(environment.apiFeeUrl + '/feeOnlineTransaction/paymentResponseMobile', value);
	}
	getAllSchoolsWithNoAuthentication() {
		return this.http.get(environment.apiSisUrl + '/users/getAllSchoolsWithNoAuthentication');
	}
	startLoader(type) {
		this.service.startLoading(type);
	}
	getFamilyWiseFeeReceipt(value) {
		return this.http.post(environment.apiFeeUrl + '/familyInformation/getFamilyWiseFeeReceipt', value);
	}

	getFamilyInformation(value) {
		return this.http.post(environment.apiFeeUrl + '/familyInformation/getFamilyInformation', value);
	}
	getFamilyOutstandingDetail(value) {
		return this.http.post(environment.apiFeeUrl + '/familyInformation/getFamilyOutstandingDetail', value);
	}

	printFamilyInvoice(value) {
		return this.http.post(environment.apiFeeUrl + '/familyInformation/printFamilyInvoice', value);
	}
	setProcesstype(value) {
		this.processType = value;
	}
	getProcesstype() {
		if (this.processType) {
			return this.processType;
		}
	}
	resetProcesstype() {
		this.processType = null;
	}
	getSession() {
		return this.http.get(environment.apiSisUrl + '/siSetup/session');
	}
	getClassSectionWiseTimeTable(value) {
		return this.http.post(environment.apiSmartUrl + '/smttimetable/getClassSectionWiseTimeTable', value);
	}
	getClasswork(value) {
		return this.http.post(environment.apiSmartUrl + '/classwork/getClasswork', value);
	}
	getAssignment(value: any) {
		return this.http.post(environment.apiSmartUrl + '/assignment/getAssignment', value);
	}
	getSchool() {
		return this.http.get(environment.apiSisUrl + '/dashboard/getSchool');
	}
	getGlobalSetting(value) {
		return this.http.post(environment.apiExamUrl + '/setup/getGlobalSetting', value);
	}
	getScheduledExam(value) {
		return this.http.post(environment.apiAxiomUrl + '/assessment/getScheduledExam', value);
	}
	getClasswiseDetails(value) {
		return this.http.post(environment.apiSmartUrl + '/smttimetable/getClasswiseDetails', value);
	}
	getTimeTableId(value) {
		return this.http.post(environment.apiSmartUrl + '/smttimetable/getTimeTableId', value);
	}
	getSubjectsByClass(value) {
		return this.http.post(environment.apiSmartUrl + '/common/getSubjectsByClass', value);
	}
	getSubjectsByClass2(value) {
		return this.http.post(environment.apiAxiomUrl + '/setupdetail/getSubjectsByClass', value);
	}

	getSubject(value) {
		return this.http.post(environment.apiSmartUrl + '/setup/getSubject', value);
	}
	getHeadWiseCollection(value) {
		return this.http.post(environment.apiFeeUrl + '/feeCollectionReports/getHeadWiseCollection', value);
	}
	getFeeCollectionReportApp(value) {
		return this.http.post(environment.apiFeeUrl + '/feeCollectionReports/getFeeCollectionReportApp', value);
	}
	getBanks(value) {
		return this.http.get(environment.apiFeeUrl + '/feeSetup/getBanks');
	}
	getBanksAll(value) {
		return this.http.get(environment.apiFeeUrl + '/feeSetup/getAllBank');
	}
	getFeeOutstanding(value) {
		return this.http.post(environment.apiFeeUrl + '/feeCollectionReports/getFeeOutstanding', value);
	}
	getClassWiseFeeOutstanding(value) {
		return this.http.post(environment.apiFeeUrl + '/feeCollectionReports/getClassWiseFeeOutstanding', value);
	}
	getClassWiseMonthWiseSeperation(value) {
		return this.http.post(environment.apiFeeUrl + '/feeCollectionReports/getClassWiseMonthWiseSeperation', value);
	}
	generateEnrollMentReportApp(value) {
		return this.http.post(environment.apiSisUrl + '/reportSis/generateEnrollMentReportApp', value);
	}
	getTopicByClassSubject(value) {
		return this.http.post(environment.apiSmartUrl + '/setup/getTopic', value);
	}
	getSubtopicByTopicId(value) {
		return this.http.post(environment.apiSmartUrl + '/common/getSubtopicByTopicId', value);
	}
	getAllSubtopic(value) {
		return this.http.post(environment.apiSmartUrl + '/common/getAllSubtopic', value);
	}
	getClassTerm(value) {
		return this.http.post(environment.apiExamUrl + '/common/getClassTerm', value);
	}
	searchReservoirByStatus(value) {
		return this.http.post(environment.apiReservUrl + '/booksearch/searchReservoirByStatus', value);
	}
	insertUserReservoirData(value) {
		return this.http.post(environment.apiReservUrl + '/issuereturnprocess/insertUserReservoirData', value);
	}
	getUserOutstandingFine(value) {
		return this.http.post(environment.apiReservUrl + '/issuereturnprocess/getUserOutstandingFine', value);

	}
	getUserReservoirData(value) {
		return this.http.post(environment.apiReservUrl + '/issuereturnprocess/getUserReservoirData', value);
	}

	updateUserReservoirData(value) {
		return this.http.post(environment.apiReservUrl + '/issuereturnprocess/updateUserReservoirData', value);
	}
	sendPushNotification(value) {
		return this.http.post(environment.apiHRUrl + '/communication/sendPushNotification', value);
	}
	getPushNotification(value) {
		return this.http.post(environment.apiHRUrl + '/communication/getPushNotification', value);
	}
	getoutbox(value) {
		return this.http.post(environment.apiHRUrl + '/communication/getoutbox', value);
	}
	getAllEmployeeByClassSectionForStaff() {
		return this.http.get(environment.apiSisUrl + '/users/getAllEmployeeByClassSectionForStaff')
	}
	getTemplate(value) {
		return this.http.post(environment.apiSisUrl + '/notificationTemplate/getNotificationTemplate', value);
	}

	getContentData(value){
		return this.http.post(environment.apiSmartUrl + '/content/getContentData', value)
	}
	
	logout() {
		return this.http.post(environment.apiSisUrl + '/users/logout', {
			"au_login_id": this.loginId,
			"user_default_data": "{\"pro_url\":\"classroom-app\",\"ses_id\":\"6\"}"
		});
	}

}
