<ion-content>
  <ion-header collapse="condense">
    <!-- <ion-toolbar> -->
    <ion-title size="large">Auth Code</ion-title>
    <!-- </ion-toolbar> -->
  </ion-header>
  <ion-card>  
    <ion-card-content>
      <ion-grid>
        <ion-row  class="ion-text-center">
          <form (ngSubmit)="validateAuthCode()">
            <ion-item>
              <ion-label>Name : </ion-label>
              <ion-input type="text" [(ngModel)]="auth.name" name="name"></ion-input>
            </ion-item>
            <ion-item>
              <ion-label>Code : </ion-label>
              <ion-textarea [(ngModel)]="auth.code" name="code"></ion-textarea>
            </ion-item>
            <br>
            <!-- <ion-item> -->
              <ion-button type="submit" block>Get Access</ion-button>
            <!-- </ion-item> -->
          </form>
        </ion-row>
      </ion-grid>      
    </ion-card-content>
  </ion-card>
</ion-content>