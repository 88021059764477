import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../src/environments/environment';
import { LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
@Injectable()
export class CommonAPIService {
	menus: any[] = [];
	homeUrl: string;
	userData: any;
	loaderType: any;
	userRoleId: any;
	paySubject = new Subject();
	userMenuArray: any[] = [
		{
			role_id: 2,
			menus: [
				{
					page_id: '1',
					title: 'Dashboard',
					url: 'admin-app/admin',
					icon: '../assets/images/White/dashboard.svg',
					menu_id: 'dash',
					subMenu: []
				},
				{
					page_id: '2',
					title: 'Collection Report',
					menu_id: '534',
					url: 'admin-app/admin/collection-report',
					icon: '../assets/images/White2/collection_report.svg',
					subMenu: []
				},
				{
					page_id: '3',
					title: 'Outstanding Report',
					menu_id: '535',
					url: 'admin-app/admin/outstanding-report',
					icon: '../assets/images/White2/outstanding.svg',
					subMenu: []
				},
				{
					page_id: '4',
					menu_id: '536',
					title: 'Enrollment Report',
					url: 'admin-app/admin/enrollment-report',
					icon: '../assets/images/White2/enrollment_report.svg',
					subMenu: []
				},
				{
					page_id: '5',
					title: 'Academics',
					menu_id: '537',
					url: 'admin-app/admin/logentry',
					icon: '../assets/images/White2/Log_report.svg',
					subMenu: []
				},
				{
					page_id: '',
					title: 'Question',
					menu_id: '790',
					class: 'menu-lvl-2-row-default',
					url: 'admin-app/admin/question',
					icon: '../assets/images/White2/teachers_leave_reports.svg',
					subMenu: [
						{
							name: 'Add Question',
							icon: '../assets/images/White/classwork.svg',
							url: 'admin-app/admin/question',
							menu_id: '791',
						},
					]
				},
				{
					page_id: '6',
					title: 'Comparative Analysis',
					menu_id: '538',
					url: 'admin-app/admin/comaparative-analysis',
					icon: '../assets/images/White2/comparative_analysis.svg',
					subMenu: []

				},
				{
					page_id: '7',
					title: 'Syllabus',
					menu_id: '539',
					url: 'admin-app/admin/logentry/syllabus',
					icon: '../assets/images/White2/report_card.svg',
					subMenu: []

				},
				{
					page_id: '8',
					title: 'Leave Management',
					menu_id: '540',
					url: 'admin-app/admin/hr/leave-request',
					icon: '../assets/images/White2/report_card.svg',
					subMenu: []

				},
				{
					page_id: '9',
					title: 'Attendance Report',
					menu_id: '541',
					url: 'admin-app/admin/attendance-report',
					icon: '../assets/images/White2/report_card.svg',
					subMenu: []

				},
				{
					page_id: '10',
					title: 'Salary Sheet',
					url: 'admin-app/admin/communication',
					icon: '../assets/images/White/assinments.svg',
					menu_id: '757',
					subMenu: []
				},
				{
					page_id: '11',
					title: 'Notice Board',
					url: 'admin-app/admin/communication',
					icon: '../assets/images/White/communications.svg',
					menu_id: '758',
					subMenu: []
				},
				{
					page_id: '',
					title: 'Communication',
					url: 'smart/teacher/communication-model',
					icon: '../assets/images/White/fees.svg',
					class: 'menu-lvl-2-row-default',
					menu_id: '857',
					subMenu: [{
						name: 'Inbox',
						icon: '../assets/images/White/fees.svg',
						url: 'smart/teacher/communication-model/inbox',
						menu_id: '858',
					},
					{
						name: 'Outbox',
						icon: '../assets/images/White/fees.svg',
						url: 'smart/teacher/communication-model/outbox',
						menu_id: '859',
					}]
				},
				{
					page_id: '',
					title: 'Transport',
					url: 'admin-app/admin/transport',
					icon: '../assets/images/White/fees.svg',
					class: 'menu-lvl-2-row-default',
					menu_id: '857',
					subMenu: [{
						name: 'Trip start/stop',
						icon: '../assets/images/White/fees.svg',
						url: 'admin-app/admin/transport/trip-start-stop',
						menu_id: '858',
					},
					{
						name: 'Vehicle Checklist',
						icon: '../assets/images/White/fees.svg',
						url: 'admin-app/admin/transport/vehicle-checklist',
						menu_id: '859',
					},
					{
						name: 'Log Entry',
						icon: '../assets/images/White/fees.svg',
						url: 'admin-app/admin/transport/log-entry',
						menu_id: '859',
					},
					{
						name: 'Vehicle Attendance',
						icon: '../assets/images/White/fees.svg',
						url: 'admin-app/admin/transport/transport-attendance',
						menu_id: '859',
					},
					{
						name: 'Live Location',
						icon: '../assets/images/White/fees.svg',
						url: 'admin-app/admin/transport/live-location',
						menu_id: '894',
					}
				]
				},
			]
		},
		{
			role_id: 3,
			menus: [
				{
					page_id: '',
					title: 'Academics',
					menu_id: '543',
					url: 'smart/teacher/logentry',
					class: 'menu-lvl-2-row-default',
					icon: '../assets/images/app-menus/BranchTransfer.svg',
					subMenu: [
						{
							name: 'Log Entry',
							icon: '../assets/images/White/classwork.svg',
							url: 'smart/teacher/logentry',
							menu_id: '544',
						},
						{
							name: 'Assignment',
							icon: '../assets/images/app-menus/assignments.svg',
							url: 'smart/teacher/assignments',
							menu_id: '545',
						},
						{
							name: 'Time Table',
							icon: '../assets/images/White/timetable.svg',
							url: 'smart/teacher/timetable',
							menu_id: '546',
						},
						{
							name: 'Leave Request',
							icon: '../assets/images/app-menus/LeaveMaster.svg',
							url: 'smart/teacher/hr/leave-request',
							menu_id: '547',
						},
						{
							name: 'Library',
							icon: '../assets/images/app-menus/question_bank.svg',
							url: 'smart/teacher/library',
							menu_id: '548',
						},
					]
				},
				{
					page_id: '',
					title: 'Examination',
					url: 'smart/teacher/mark-attendance',
					menu_id: '549',
					class: 'menu-lvl-2-row-default',
					icon: '../assets/images/app-menus/performance_report.svg',
					subMenu: [
						{
							name: 'Mark-Attendance',
							icon: '../assets/images/White/mark_attendance.svg',
							url: 'smart/teacher/mark-attendance',
							menu_id: '550',
						},
						{
							name: 'Remarks Entry',
							icon: '../assets/images/White/remarks_entry.svg',
							url: 'smart/teacher/remarks-entry',
							menu_id: '551',
						},
						{
							name: 'Marks Entry',
							icon: '../assets/images/White/marks_entry.svg',
							url: 'smart/teacher/marks-entry',
							menu_id: '552',
						},
						{
							name: 'Health Status',
							icon: '../assets/images/app-menus/health_status.svg',
							url: 'smart/teacher/health-status',
							menu_id: '607',
						},
						{
							name: 'Result Entry',
							icon: '../assets/images/app-menus/result_entry.svg',
							url: 'smart/teacher/witheld-detained',
							menu_id: '609',
						},
						{
							name: 'Achievements',
							icon: '../assets/images/app-menus/achievements.svg',
							url: 'smart/teacher/achievements',
							menu_id: '610',
						},
						{
							name: 'Roll No. Entry',
							icon: '../assets/images/app-menus/e_mail-1.svg',
							url: 'smart/teacher/rollno',
							menu_id: '614',
						}
					]
				},
				{
					page_id: '',
					title: 'Question',
					menu_id: '790',
					class: 'menu-lvl-2-row-default',
					url: 'smart/teacher/question',
					icon: '../assets/images/White2/teachers_leave_reports.svg',
					subMenu: [
						{
							name: 'Add Question',
							icon: '../assets/images/White/classwork.svg',
							url: 'smart/teacher/question',
							menu_id: '791',
						},
					]
				},
				{
					page_id: '3',
					title: 'Reports',
					url: 'smart/teacher/reports',
					icon: '../assets/images/app-menus/record_of_accessment.svg',
					menu_id: '553',
					subMenu: []
				}
				,
				{
					page_id: '',
					title: 'Communication',
					url: 'smart/teacher/communication-model',
					icon: '../assets/images/White/fees.svg',
					class: 'menu-lvl-2-row-default',
					menu_id: '857',
					subMenu: [{
						name: 'Inbox',
						icon: '../assets/images/White/fees.svg',
						url: 'smart/teacher/communication-model/inbox',
						menu_id: '858',
					},
					{
						name: 'Outbox',
						icon: '../assets/images/White/fees.svg',
						url: 'smart/teacher/communication-model/outbox',
						menu_id: '859',
					}]
				},
			]
		}
	];
	tabidTeacher: any = '';
	slidesOpts = {
		grabCursor: true,
		cubeEffect: {
			shadow: false,
			slideShadows: false,
			shadowOffset: 20,
			shadowScale: 1.50,
		},
		on: {
			beforeInit: function () {
				const swiper = this;
				swiper.classNames.push(`${swiper.params.containerModifierClass}cube`);
				swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);

				const overwriteParams = {
					slidesPerView: 1,
					slidesPerColumn: 1,
					slidesPerGroup: 1,
					watchSlidesProgress: true,
					resistanceRatio: 0,
					spaceBetween: 0,
					centeredSlides: false,
					virtualTranslate: true,
				};

				this.params = Object.assign(this.params, overwriteParams);
				this.originalParams = Object.assign(this.originalParams, overwriteParams);
			},
			setTranslate: function () {
				const swiper = this;
				const {
					$el, $wrapperEl, slides, width: swiperWidth, height: swiperHeight, rtlTranslate: rtl, size: swiperSize,
				} = swiper;
				const params = swiper.params.cubeEffect;
				const isHorizontal = swiper.isHorizontal();
				const isVirtual = swiper.virtual && swiper.params.virtual.enabled;
				let wrapperRotate = 0;
				let $cubeShadowEl;
				if (params.shadow) {
					if (isHorizontal) {
						$cubeShadowEl = $wrapperEl.find('.swiper-cube-shadow');
						if ($cubeShadowEl.length === 0) {
							$cubeShadowEl = swiper.$('<div class="swiper-cube-shadow"></div>');
							$wrapperEl.append($cubeShadowEl);
						}
						$cubeShadowEl.css({ height: `${swiperWidth}px` });
					} else {
						$cubeShadowEl = $el.find('.swiper-cube-shadow');
						if ($cubeShadowEl.length === 0) {
							$cubeShadowEl = swiper.$('<div class="swiper-cube-shadow"></div>');
							$el.append($cubeShadowEl);
						}
					}
				}

				for (let i = 0; i < slides.length; i += 1) {
					const $slideEl = slides.eq(i);
					let slideIndex = i;
					if (isVirtual) {
						slideIndex = parseInt($slideEl.attr('data-swiper-slide-index'), 10);
					}
					let slideAngle = slideIndex * 90;
					let round = Math.floor(slideAngle / 360);
					if (rtl) {
						slideAngle = -slideAngle;
						round = Math.floor(-slideAngle / 360);
					}
					const progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
					let tx = 0;
					let ty = 0;
					let tz = 0;
					if (slideIndex % 4 === 0) {
						tx = -round * 4 * swiperSize;
						tz = 0;
					} else if ((slideIndex - 1) % 4 === 0) {
						tx = 0;
						tz = -round * 4 * swiperSize;
					} else if ((slideIndex - 2) % 4 === 0) {
						tx = swiperSize + (round * 4 * swiperSize);
						tz = swiperSize;
					} else if ((slideIndex - 3) % 4 === 0) {
						tx = -swiperSize;
						tz = (3 * swiperSize) + (swiperSize * 4 * round);
					}
					if (rtl) {
						tx = -tx;
					}

					if (!isHorizontal) {
						ty = tx;
						tx = 0;
					}

					// tslint:disable-next-line:max-line-length
					const transform$$1 = `rotateX(${isHorizontal ? 0 : -slideAngle}deg) rotateY(${isHorizontal ? slideAngle : 0}deg) translate3d(${tx}px, ${ty}px, ${tz}px)`;
					if (progress <= 1 && progress > -1) {
						wrapperRotate = (slideIndex * 90) + (progress * 90);
						if (rtl) { wrapperRotate = (-slideIndex * 90) - (progress * 90); }
					}
					$slideEl.transform(transform$$1);
					if (params.slideShadows) {
						// Set shadows
						let shadowBefore = isHorizontal ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
						let shadowAfter = isHorizontal ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
						if (shadowBefore.length === 0) {
							shadowBefore = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'left' : 'top'}"></div>`);
							$slideEl.append(shadowBefore);
						}
						if (shadowAfter.length === 0) {
							shadowAfter = swiper.$(`<div class="swiper-slide-shadow-${isHorizontal ? 'right' : 'bottom'}"></div>`);
							$slideEl.append(shadowAfter);
						}
						if (shadowBefore.length) { shadowBefore[0].style.opacity = Math.max(-progress, 0); }
						if (shadowAfter.length) { shadowAfter[0].style.opacity = Math.max(progress, 0); }
					}
				}
				$wrapperEl.css({
					'-webkit-transform-origin': `50% 50% -${swiperSize / 2}px`,
					'-moz-transform-origin': `50% 50% -${swiperSize / 2}px`,
					'-ms-transform-origin': `50% 50% -${swiperSize / 2}px`,
					'transform-origin': `50% 50% -${swiperSize / 2}px`,
				});

				if (params.shadow) {
					if (isHorizontal) {
						// tslint:disable-next-line:max-line-length
						$cubeShadowEl.transform(`translate3d(0px, ${(swiperWidth / 2) + params.shadowOffset}px, ${-swiperWidth / 2}px) rotateX(90deg) rotateZ(0deg) scale(${params.shadowScale})`);
					} else {
						const shadowAngle = Math.abs(wrapperRotate) - (Math.floor(Math.abs(wrapperRotate) / 90) * 90);
						const multiplier = 1.5 - (
							(Math.sin((shadowAngle * 2 * Math.PI) / 360) / 2)
							+ (Math.cos((shadowAngle * 2 * Math.PI) / 360) / 2)
						);
						const scale1 = params.shadowScale;
						const scale2 = params.shadowScale / multiplier;
						const offset$$1 = params.shadowOffset;
						// tslint:disable-next-line:max-line-length
						$cubeShadowEl.transform(`scale3d(${scale1}, 1, ${scale2}) translate3d(0px, ${(swiperHeight / 2) + offset$$1}px, ${-swiperHeight / 2 / scale2}px) rotateX(-90deg)`);
					}
				}

				const zFactor = (swiper.browser.isSafari || swiper.browser.isUiWebView) ? (-swiperSize / 2) : 0;
				$wrapperEl
					// tslint:disable-next-line:max-line-length
					.transform(`translate3d(0px,0,${zFactor}px) rotateX(${swiper.isHorizontal() ? 0 : wrapperRotate}deg) rotateY(${swiper.isHorizontal() ? -wrapperRotate : 0}deg)`);
			},
			setTransition: function (duration) {
				const swiper = this;
				const { $el, slides } = swiper;
				slides
					.transition(duration)
					.find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
					.transition(duration);
				if (swiper.params.cubeEffect.shadow && !swiper.isHorizontal()) {
					$el.find('.swiper-cube-shadow').transition(duration);
				}
			},
		}
	};
	isLoading = false;
	tabTeacherArray: any[] = [];
	netFlag: any;
	currentUser: any;
	tabidStudent: any = '';
	tabStudentArray: any[] = [];
	constructor(private http: HttpClient, private _cookieService: CookieService,
		public loadingController: LoadingController,
		private storage: Storage) {
		this.menus = (JSON.parse(localStorage.getItem('userAccessMenu'))) ?
			(JSON.parse(localStorage.getItem('userAccessMenu'))).menus : [];
		// this.getuserData().then(() => {
		// 	this.getMenuType();
		// });
	}
	UserAccessMenu: any[] = [];
	showLoading = new Subject();
	netWorkFlag = new Subject();
	menuToggle = new Subject();
	menuEntry = new Subject();
	htmlToText(html: any) {
		const tmp = document.createElement('DIV'); // TODO: Check if this the way to go with Angular
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || '';
	}

	startLoading(type) {
		this.loaderType = type;
		this.showLoading.next({ type: type, process: true });
	}
	menuOpen() {
		this.menuToggle.next(true);
	}
	menuClose() {
		this.menuToggle.next(false);
	}
	stopLoading(type) {
		this.showLoading.next({ type: type, process: false });
	}
	getSchoolDetails() {
		return this.http.get('/dashboard/getSchool');
	}
	getProjectList(value) {
		const param: any = {};
		return this.http.post('/dashboard/getProjectList', param);
	}

	dateConvertion(value, format) {
		const datePipe = new DatePipe('en-US');
		return datePipe.transform(value, format);
	}
	logout(value: any) {

		return this.http.post(environment.apiSisUrl + '/users/logout', value);
	}
	getUrl() {
		const currentUser = JSON.parse(localStorage.getItem('currentUser'));
		const role_id = currentUser.role_id;
		if (role_id === 2) {
			this.homeUrl = '/school';
		} else if (role_id === 3) {
			this.homeUrl = '/teacher';
		} else if (role_id === 4) {
			this.homeUrl = '/student';
		}
		//
		return this.homeUrl;
	}
	getCokkieData() {
		if (this._cookieService && this._cookieService.get('userData')) {
			return this.userData = JSON.parse(this._cookieService.get('userData'));
		}
	}
	getSession() {
		return this.http.get(environment.apiSisUrl + '/siSetup/session');
	}
	getSchool() {
		return this.http.get(environment.apiSisUrl + '/dashboard/getSchool');
	}
	setTabIdTeacherApp(tab_id) {
		this.tabidTeacher = tab_id;
	}
	getTabIdTeacherApp() {
		return this.tabidTeacher;
	}
	setTabIdTeacherArray(tab_array: any[]) {
		this.tabTeacherArray = tab_array;
	}
	getTabIdStudentArray() {
		return this.tabStudentArray;
	}
	setTabIdStudentApp(tab_id) {
		this.tabidStudent = tab_id;
	}
	getTabIdStudentApp() {
		return this.tabidStudent;
	}
	setTabIdStudentArray(tab_array: any[]) {
		this.tabStudentArray = tab_array;
	}
	getTabIdTeacherArray() {
		return this.tabTeacherArray;
	}
	isNetwork() {
		this.netWorkFlag.next(true);
	}
	setNetwork(flag) {
		this.netFlag = flag;
	}
	isEntry() {
		this.menuEntry.next(true);
	}
	noEntry() {
		this.menuEntry.next(false);
	}
	getNetwork() {
		return this.netFlag;
	}
	noNetwork() {
		this.netWorkFlag.next(false);
	}
	getMenuType() {
		return this.userMenuArray;
	}
	succeesPay() {
		this.paySubject.next(true);
	}
	failurePay() {
		this.paySubject.next(false);
	}
}
