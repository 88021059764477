export const environment = {
	production: true,
	apiAxiomUrl: 'https://apiaxiom.invictusdigisoft.com',
	apiSisUrl: 'https://apisis.invictusdigisoft.com',
	apiFeeUrl: 'https://apifee.invictusdigisoft.com',
	apiSmartUrl: 'https://apismart.invictusdigisoft.com',
	apiExamUrl: 'https://apiexam.invictusdigisoft.com',
	apiReservUrl: 'https://apireservoir.invictusdigisoft.com',
	apiHRUrl: 'https://apihr.invictusdigisoft.com',
	apiInvUrl: 'https://apiinventory.invictusdigisoft.com',
	apiFaUrl: 'https://apifa.invictusdigisoft.com',
	apiTransportUrl: 'https://apitransport.invictusdigisoft.com',
	apiHostelUrl: 'https://apihostel.invictusdigisoft.com',
	apiWhatsappBackendURl: "https://apiwhatsapp.invictusdigisoft.com",
	logoutUrl: 'https://login.invictusdigisoft.com',
	socketUrl: 'https://sockets.invictusdigisoft.com',
	helpDeskUrl: "https://support.invictusdigisoft.com/autologin.php",
	helpDeskAgentUrl: "https://support.invictusprojects.com/scp/login.php",
	payuApi : 'https://secure.payu.in/_payment',
	reverseGeoCoding: "https://maps.googleapis.com/maps/api/geocode/json",
	googleMapsKey: "",
	testInitiateCode: "T100",
	testStartCode: "T101",
	testOngoingCode: "T102",
	testSubmitCode: "T103",
	testInternetErrorCode: "T104",
	testSuspiciousErrorCode: "T105",
	testExtendCode: "T106",
	testEndCode: "T107",
	firebase: {
		apiKey: "AIzaSyBFlxD3sEuY1KuBTSKS-wulBy2h379arEw",
		authDomain: "smart-class-4d3ae.firebaseapp.com",
		projectId: "smart-class-4d3ae",
		storageBucket: "smart-class-4d3ae.appspot.com",
		messagingSenderId: "845964957723",
		appId: "1:845964957723:web:a0c14fceba6fe66237c0be",
		measurementId: "G-G8MW2YVQFG"
	  },
};
