import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
	HttpEvent, HttpInterceptor,
	HttpHandler, HttpRequest, HttpResponse,
	HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { map, catchError, retry, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { CommonAPIService } from '../service/commonAPI.service';
import { ErpCommonService } from '../service/erp-common.service';
@Injectable()
export class SuccessErrorInterceptor implements HttpInterceptor {
	cookieData: any;
	constructor(private service: CommonAPIService,
		private erp: ErpCommonService,
		private router: Router,
		private platform: Platform,
		private storage: Storage) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		console.log("token >>>>>>>>>>>>>>>>>>>>>>>>>>", this.storage.get('userData'));
		return from(this.storage.get('userData'))
			.pipe(
				switchMap(token => {
					
					if (token) {
						this.cookieData = JSON.parse(token);
						if (this.cookieData) {
							if (this.cookieData['PF']) {
								request = request.clone({ headers: request.headers.set('Prefix', this.cookieData['PF']) });
								request = request.clone({ headers: request.headers.set('Loginid', this.cookieData['LN']) });
								request = request.clone({ headers: request.headers.set('Token', this.cookieData['AN']) });
								request = request.clone({ headers: request.headers.set('CID', this.cookieData['CID']) });
							}
							const deviceType = JSON.parse(this.cookieData['device_details']);

								if (deviceType && deviceType.length > 0) {
									request = request.clone({ headers: request.headers.set('DeviceType', (this.platform.is('android') ? 'android' : (this.platform.is('ios') ? 'ios' : 'web'))) });
								}
							if (this.cookieData['SESSION']) {
								request = request.clone({ headers: request.headers.set('Sessionid', this.cookieData['SESSION']) });
							}
							if (this.erp.getProcesstype()) {
								request = request.clone({ headers: request.headers.set('Processtype', this.erp.getProcesstype()) });
							}
						}
					}
					return next.handle(request).pipe(
						map((event: HttpEvent<any>) => {
							if (event instanceof HttpResponse) {
								this.service.stopLoading(this.service.loaderType);
								if (event.body.status === 'error' &&
									(event.body.data === 'Token Expired' || event.body.data === 'Logout Successfully' ||
										event.body.data === 'Token Not Matched')) {
									localStorage.clear();
									this.storage.remove('currentUser');
									this.storage.remove('project');
									this.storage.remove('userData');
									this.storage.remove('session');
									this.storage.remove('userDetail');
									this.router.navigate(['/login']);
								}
							}
							return event;
						}),
						catchError((error: HttpErrorResponse) => {
							if (error.error instanceof Error) {
								this.service.stopLoading(this.service.loaderType);
								// A client-side or network error occurred.
								retry(3);
							} else {
								this.service.stopLoading(this.service.loaderType);
							}
							return throwError(error);
						}));
				})
			);
	}
}
